import React from 'react';

const LinkView = ({ data }) => {
  const [hasLink, setHasLink] = React.useState(false);

  React.useEffect(() => {
    if (data.link) {
      if (data.link && data.link.length > 0) {
        setHasLink(true);
      }
      if (data.link.length === 0) {
        setHasLink(false);
      }
    }
  }, [data.href]);

  let link = hasLink ? (
    data.isDownload ? (
      <div style={{ textAlign: data.styles.align }}>
        <a className={data.styles.isButton ? 'linkBlockLink linkBlockButton' : 'linkBlockLink'}
          href={data.link[0]?.['@id'] + '/@@download/' + data.link[0]?.['@type'].toLowerCase()} download={data.link[0]?.id}
          style={{ '--background-color': data.styles.backgroundColor, '--text-color': data.styles.textColor, '--hover-color': data.styles.hoverColor, fontSize: data.styles.textSize }}>
          <i class={data.name == null ? 'ri-download-2-line noName' : 'ri-download-2-line'}></i>{data.name}
        </a>
      </div>
    ) : (
      <div style={{ textAlign: data.styles.align }}>
        <a className={data.styles.isButton ? 'linkBlockLink linkBlockButton' : 'linkBlockLink'}
          href={data.link[0]?.['@id']} target={data.target ? '_blank' : '_self'}
          style={{ '--background-color': data.styles.backgroundColor, '--text-color': data.styles.textColor, '--hover-color': data.styles.hoverColor, fontSize: data.styles.textSize }}>
          {data.name}
        </a>
      </div>
    )
  ) : (
    <div style={{ textAlign: data.styles?.align }}>
      <p className={data.styles?.isButton ? 'linkBlockLink linkBlockButton' : 'linkBlockLink'} 
        style={{ '--background-color': data.styles?.backgroundColor, '--text-color': data.styles?.textColor, '--hover-color': data.styles?.hoverColor, fontSize: data.styles?.textSize }}>
        {data.isDownload &&
          <i class={data.name == null ? 'ri-download-2-line noName' : 'ri-download-2-line'}></i>
        }
        {data.name}
      </p>
    </div>
  );

  return (
    <div>
      <div className="container">
        {link}
      </div>
    </div>
  );
};

export default LinkView;