const isotopeItemSchema = (props) => {

    return {
      title: 'Tag',
      addMessage: 'Filter hinzufügen',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['tagname'],
        },
      ],

      properties: {
        tagname: {
          title: 'Name',
          widget: 'text',
          required: true,
        },
      },
      required: [],
    };
};

export const IsotopeSchema = (props) => {
    return {
        title: 'Filter',
        fieldsets: [
          {
              id: 'default',
              title: 'Default',
              fields: [ 'rooturl', 'default_tags'],
          },
        ],

        properties: {
          rooturl: {
            title: 'Url',
            description: 'Start Url für die Personen',
            widget: 'internal_url',
            required: true,
          },
          default_tags: {
              widget: 'object_list',
              title: 'Filter Tags',
              schema: isotopeItemSchema,
          },
        },
        required: [],
    };
};

export default IsotopeSchema;