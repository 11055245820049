import React from 'react';
import { NewsBoxSchema } from './NewsBoxSchema';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';

const NewsBoxData = (props) => {
  const { block, data, onChangeBlock } = props;
  const schema = NewsBoxSchema({ ...props });
  return (
    <BlockDataForm
      schema={ schema }
      title={ schema.title }
      onChangeField={(id, value) =>  {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={ data }
      block={ block } 
    />
  );
};
export default NewsBoxData;