import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { format  } from 'date-fns'

const getNewsContent = (array = []) =>
  array.map((obj, item) => {
    obj[0] = item;
    return obj;
  }, {}
);

const NewsBoxView = (props) => {
  const data = props.data;
  const contentUrl = data.newsurl;
  const subrequestkey = props.block;

  const dispatch = useDispatch();
  const news = useSelector((state) => getNewsContent(state.search.subrequests[subrequestkey]?.items));
  const content = useSelector((state) => state.workflow.transition);

  var results = [];

  useEffect(() => {
      dispatch(
        searchContent(
          contentUrl,
          {
            portal_type: ['Event'],
            fullobjects: true, 
            b_size: 1000,
            sort_on: "start",
            sort_order: "descending",
          },
          subrequestkey,
        ),
      );
  },[dispatch, content, contentUrl]);

  news.forEach((elem) => {
    results.push([elem.title, format(new Date(elem.start), 'dd.MM.yyyy'), elem['preview_image']?.download, elem['@id'], elem.UID]);
  })

  return(
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid className='newsContainer' justifyContent="center" container item spacing={4}>
            {results.map((result) => (
              <Grid item className='gridItem' key={result[4]}>
                <a className='newsBoxA' href={result[3]}>
                  <div className="newsBox">
                    <div className="newsTop">
                      <div className="newsImg" style={{ backgroundImage: `url(${result[2]})` }}></div>
                    </div>
                    <div className="newsBottom">
                      <p className="newsTitle">{result[0]}</p>
                      <p>{result[1]}</p>
                    </div>
                  </div>
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
);
};

NewsBoxView.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
};

export default NewsBoxView;