import $ from 'jquery';

$(function(){
    if(screen.width > 1070){
        $('.header-wrapper').data('size','big');
    }
});

$(window).on("load", function() {
    $(".dropdownmenu-item").each(function(elem) {
        var pos = $(this).position().left;
            $(this).next(".dropdown-menu-wrapper").css("left", pos);
    })

    if(screen.width <= 990){
        $(".ui.pointing.secondary.stackable.menu").children().wrapAll("<div class='mobileNavWrapper'></div>")
    }

    //Make the color of a link settable through the volto styling block
    var links = document.querySelectorAll("p:has(a)")
    links.forEach(e => {
        $(e).children().css("color", $(e).css("color"));
    })
})

$( window ).on( "resize", function() {
    if(screen.width > 1070){
        $('.public-ui .header-wrapper').data('size','big');
    }

    $(".dropdownmenu-item").each(function(elem) {
        var pos = $(this).position().left;
            $(this).next(".dropdown-menu-wrapper").css("left", pos);
    })
});

$(window).on('scroll', (function() {
    if(screen.width > 1070){
        if($(document).scrollTop() > 0)
        {
            if($('.public-ui .header-wrapper').data('size') == 'big')
            {
                $('.public-ui .header-wrapper').data('size','small');
                $('.public-ui .header-wrapper').stop().animate({
                    height:'6em',
                    'padding-top': '0',
                    'padding-bottom': '0',
                },600);
                $('.public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img').stop().animate({
                    height:'5.5em',
                },600);
            }
        }
        else
        {
            if($('.public-ui .header-wrapper').data('size') == 'small')
            {
                $('.public-ui .header-wrapper').data('size','big');
                $('.public-ui .header-wrapper').stop().animate({
                    height:'10em',
                    'padding-top': '14px',
                    'padding-bottom': '14px',
                },600);
                $('.public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img').stop().animate({
                    height:'8em',
                },600);
            }  
        }
    }
}));